import React from 'react'
import axios from 'axios'
import styled, { css, keyframes } from 'styled-components'
import { connect } from 'react-redux'
import InViewMonitor from 'react-inview-monitor'

import SEO from '../components/seo'

import * as Layout from '../layouts/layout'
import * as FadeIn from '../components/fadeIn'

const API_PATH = 'https://api.tryhard.me/fuyoen/contact/index.php'

class Contact extends React.Component {
  state = {
    name: '',
    furigana: '',
    tel: '',
    email: '',
    message: '',
    radio: 'ご意見・ご要望',
    mailSent: false,
    error: null,
  }

  handleFormSubmit = e => {
    e.preventDefault()
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: this.state,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
        })
      })
      .catch(error => this.setState({ error: error.message }))
  }

  render() {
    const lang = this.props.lang
    return (
      <>
        <SEO title="Contact" />
        <Hero className="hero">
          <HeroHeader>
            <FadeIn.Left margin="0%">
              <h1>
                {lang === 'ja' && 'お問い合わせ'}
                {lang === 'en' &&
                  'I want to convey the true taste of authentic Sichuan cuisine'}
                {lang === 'zh' && '咨询'}
              </h1>
            </FadeIn.Left>
          </HeroHeader>
        </Hero>
        <Layout.Section id="form">
          <form action="#">
            <ContactContainer>
              <ContactHeader>
                <FadeIn.Up>
                  <h3>
                    {lang === 'ja' && 'お問い合わせはこちら'}
                    {lang === 'en' &&
                      'I want to convey the true taste of authentic Sichuan cuisine'}
                    {lang === 'zh' && '点击这里咨询'}
                  </h3>
                </FadeIn.Up>
              </ContactHeader>
              <ContactRadioRow>
                <ContactRadio>
                  <input
                    type="radio"
                    id="opinion"
                    name="opinion"
                    value="ご意見・ご要望"
                    checked={this.state.radio === 'ご意見・ご要望'}
                    onChange={e => this.setState({ radio: e.target.value })}
                  />
                  <label htmlFor="opinion">
                    {lang === 'ja' && 'ご意見・ご要望'}
                    {lang === 'en' &&
                      'I want to convey the true taste of authentic Sichuan cuisine'}
                    {lang === 'zh' && '意见'}
                  </label>
                </ContactRadio>
                <ContactRadio>
                  <input
                    type="radio"
                    id="media"
                    name="media"
                    value="メディア取材・報道関係に関するお問い合わせ"
                    checked={
                      this.state.radio ===
                      'メディア取材・報道関係に関するお問い合わせ'
                    }
                    onChange={e => this.setState({ radio: e.target.value })}
                  />
                  <label htmlFor="media">
                    {lang === 'ja' &&
                      'メディア取材・報道関係に関するお問い合わせ'}
                    {lang === 'en' &&
                      'I want to convey the true taste of authentic Sichuan cuisine'}
                    {lang === 'zh' && '媒体采访・报导相关咨询'}
                  </label>
                </ContactRadio>
                <ContactRadio>
                  <input
                    type="radio"
                    id="other"
                    name="other"
                    value="その他"
                    checked={this.state.radio === 'その他'}
                    onChange={e => this.setState({ radio: e.target.value })}
                  />
                  <label htmlFor="other">
                    {lang === 'ja' && 'その他'}
                    {lang === 'en' &&
                      'I want to convey the true taste of authentic Sichuan cuisine'}
                    {lang === 'zh' && '其他'}
                  </label>
                </ContactRadio>
              </ContactRadioRow>
              <ContactRow>
                <ContactTitleOdd>
                  <label htmlFor="name">
                    {lang === 'ja' && '氏名'}
                    {lang === 'en' &&
                      'I want to convey the true taste of authentic Sichuan cuisine'}
                    {lang === 'zh' && '姓名'}
                  </label>
                </ContactTitleOdd>
                <ContactBody>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="芙蓉　太朗"
                    required=""
                    value={this.state.name}
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                </ContactBody>
              </ContactRow>
              <ContactRow>
                <ContactTitleEven>
                  <label htmlFor="furigana">
                    {lang === 'ja' && 'フリガナ'}
                    {lang === 'en' &&
                      'I want to convey the true taste of authentic Sichuan cuisine'}
                    {lang === 'zh' && '片假名'}
                  </label>
                </ContactTitleEven>
                <ContactBody>
                  <input
                    type="text"
                    id="furigana"
                    name="furigana"
                    placeholder="フヨウ　タロウ"
                    required=""
                    value={this.state.furigana}
                    onChange={e => this.setState({ furigana: e.target.value })}
                  />
                </ContactBody>
              </ContactRow>
              <ContactRow>
                <ContactTitleOdd>
                  <label htmlFor="tel">
                    {lang === 'ja' && '電話番号'}
                    {lang === 'en' &&
                      'I want to convey the true taste of authentic Sichuan cuisine'}
                    {lang === 'zh' && '电话号码'}
                  </label>
                </ContactTitleOdd>
                <ContactBody>
                  <input
                    type="text"
                    id="tel"
                    name="tel"
                    placeholder="電話番号"
                    required=""
                    value={this.state.tel}
                    onChange={e => this.setState({ tel: e.target.value })}
                  />
                </ContactBody>
              </ContactRow>
              <ContactRow>
                <ContactTitleEven>
                  <label htmlFor="email">
                    {lang === 'ja' && 'メールアドレス'}
                    {lang === 'en' &&
                      'I want to convey the true taste of authentic Sichuan cuisine'}
                    {lang === 'zh' && '邮箱'}
                  </label>
                </ContactTitleEven>
                <ContactBody>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="example@example.com"
                    required=""
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                </ContactBody>
              </ContactRow>
              <ContactRow>
                <ContactTitleOdd>
                  <label htmlFor="message">
                    {lang === 'ja' && '本文'}
                    {lang === 'en' &&
                      'I want to convey the true taste of authentic Sichuan cuisine'}
                    {lang === 'zh' && '内容'}
                  </label>
                </ContactTitleOdd>
                <ContactBody>
                  <textarea
                    id="message"
                    name="message"
                    placeholder="お問い合わせ内容をご記入ください"
                    value={this.state.message}
                    onChange={e => this.setState({ message: e.target.value })}
                  />
                </ContactBody>
                {this.state.mailSent && (
                  <Success>
                    {lang === 'ja' && 'ご応募ありがとうございました。'}
                    {lang === 'en' &&
                      'I want to convey the true taste of authentic Sichuan cuisine'}
                    {lang === 'zh' && '谢谢您的申请'}
                  </Success>
                )}
              </ContactRow>
              <ContactSubmit>
                <p>
                  {lang === 'ja' && 'メールにて折り返しご連絡いたします'}
                  {lang === 'en' &&
                    'I want to convey the true taste of authentic Sichuan cuisine'}
                  {lang === 'zh' && '通过邮件回复您'}
                </p>
                <ContactButtons>
                  <FadeIn.Up>
                    <ContactButton onClick={e => this.handleFormSubmit(e)}>
                      <p>
                        {this.props.lang === 'ja' && '送信'}
                        {this.props.lang === 'en' && 'Send'}
                        {this.props.lang === 'zh' && '发送'}
                      </p>
                    </ContactButton>
                  </FadeIn.Up>
                </ContactButtons>
              </ContactSubmit>
            </ContactContainer>
          </form>
        </Layout.Section>
      </>
    )
  }
}

const mapStateToProps = state => ({
  lang: state.lang,
})

export default connect(mapStateToProps)(Contact)

const pink = '#e3007f'
const beige = '#fadcbe'
const lightBeige = '#FEF8F2'
const brown = '#603813'
const darkBrown = '#42210B'

const Hero = styled.div`
  position: relative;
  height: 66.6666666666vw;
  max-height: 600px;
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/img/contact-hero.jpg) center/cover;
  animation: 15s ${Layout.ZoomBg} infinite;
`

const HeroHeader = styled.header`
  position: absolute;
  transform: translate(-50%, -50%);
  color: #fff;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: calc(100% - 60px);
  @media (min-width: 1025px) {
    max-width: 450px;
    left: 30%;
  }
  h1 {
    line-height: 2;
    letter-spacing: 10px;
    font-size: 1.5rem;
  }
  p {
    line-height: 1.4;
    letter-spacing: 5px;
  }
`

// const Success = styled.div`
//   background: yellow;
//   color: #000;
//   padding: 15px;
//   width: 100%;
//   margin-top: 15px;
//   margin-bottom: 15px;
// `

const ContactContainer = styled(Layout.Container)`
  max-width: 960px;
`

const ContactRow = styled(Layout.Row)`
  margin-bottom: 5px;
  margin-left: 0;
  margin-right: 0;
`

const ContactHeader = styled.div`
  color: ${brown};
  padding: 0px 30px 30px;
  h3 {
    text-align: center;
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 70px;
      background: ${brown};
      margin-top: 23px;
      margin-bottom: 23px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    padding: 50px 30px;
    h3 {
      font-size: 38px;
      &:after {
        margin-top: 38px;
        margin-bottom: 38px;
      }
    }
    p {
      font-size: 18px;
    }
  }
`

const ContactTitleOdd = styled.div`
  position: relative;
  background: ${lightBeige};
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${brown};
  padding: 15px;
  &::before {
    content: '';
    display: block;
    width: 5px;
    height: 100%;
    background: ${brown};
    left: 0;
    position: absolute;
  }
  @media (min-width: 415px) {
    width: 20%;
  }
`

const ContactTitleEven = styled.div`
  position: relative;
  background: #ececec;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${brown};
  padding: 15px;
  &::before {
    content: '';
    display: block;
    width: 5px;
    height: 100%;
    background: ${brown};
    left: 0;
    position: absolute;
  }
  @media (min-width: 415px) {
    width: 20%;
  }
`

const ContactBody = styled.div`
  padding: 5px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    padding: 15px;
    border: 3px solid ${lightBeige};
    border-radius: 3px;
    font-size: 16px;
  }
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    padding: 15px;
    border: 3px solid ${lightBeige};
    border-radius: 3px;
    font-size: 16px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    padding: 15px;
    border: 3px solid ${lightBeige};
    border-radius: 3px;
    font-size: 16px;
    background: #fff;
  }
  .selectWrap {
    position: relative;
    width: 33%;
    &::after {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border: 0px;
      border-bottom: solid 2px #000;
      border-right: solid 2px #000;
      transform: translateY(-50%) rotate(45deg);
      position: absolute;
      top: 50%;
      right: 15px;
    }
  }
  @media (min-width: 415px) {
    width: 80%;
  }
`

const ContactSubmit = styled.div`
  p {
    color: ${brown};
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
`
const ContactButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContactButton = styled.button`
  position: relative;
  z-index: 1;
  p {
    background: linear-gradient(90deg, ${pink} 0%, ${beige} 33.3333333333%);
    background-size: 300%;
    background-position: 0;
    position: relative;
    display: block;
    padding: 20px 30px;
    margin-top: 0;
    margin-bottom: 0;
    color: #fff;
    transition: transform 0.5s ease, box-shadow 0.5s ease;

    &:before {
      font-size: 18px;
      content: '→';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &:hover {
      animation: 1s ${Layout.SlideBg} 1;
      transform: translate(10px, 10px);
    }
  }
  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: ${brown};
    top: 10px;
    left: 10px;
    z-index: -1;
  }
`

const Success = styled.div`
  background: ${beige};
  color: ${brown};
  padding: 15px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
`

const ContactRadio = styled.div`
  display: inline-block;
  margin-right: 15px;
  input {
    line-height: 1;
  }
  label {
    line-height: 1;
    margin-left: 5px;
  }
`

const ContactRadioRow = styled(Layout.Row)`
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 0;
`
